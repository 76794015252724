import { UpdateProjectForm } from '@/composables/campaign/brand_lift/update';
import { useProjectFormIF } from '@/composables/campaign/brand_lift/project';
import { useRoute, useRouter } from 'vue-router';
import { toast } from '@/components/ui/Toast';
import { CAMPAIGN_ROUTES } from '@/router';
import { handleError } from '@/common/handleError';
import useLoading from '@/composables/loading';

export class CloneProjectForm extends UpdateProjectForm {}
export const useCloneProjectForm = async (): Promise<useProjectFormIF> => {
  const route = useRoute();
  const router = useRouter();

  const projectId = Number(route.params.projectId);
  const companyId = Number(route.params.companyId);
  if (!companyId) {
    toast({ title: 'カンパニーが選択されていません', variant: 'error' });
  }
  const form = new CloneProjectForm(companyId);
  await form.fromReachAnalysisProject(projectId);
  form.projectName.value = form.projectName.value + '_コピー';

  const _cloneProject = async () => {
    try {
      await form.createProject();
      await router.push({ name: CAMPAIGN_ROUTES.index, params: route.params });
    } catch (e) {
      handleError(e);
    }
  };

  const [isCloningProject, cloneProject] = useLoading(_cloneProject);

  return {
    form,
    submit: cloneProject,
    isSubmitting: isCloningProject
  };
};
