import {
  ProjectForm,
  useCreateProjectForm
} from '@/composables/campaign/brand_lift/create';
import { computed, ref, Ref } from 'vue';
import {
  UpdateProjectForm,
  useUpdateProjectForm
} from '@/composables/campaign/brand_lift/update';
import {
  CloneProjectForm,
  useCloneProjectForm
} from '@/composables/campaign/brand_lift/clone';
import { AreaOptions, useAreaOptions } from '@/store/areaOptions';
import { AreaInfo } from '@/api/openapi';
import { storeToRefs } from 'pinia';
import { useRoute } from 'vue-router';
import { toast } from '@/components/ui/Toast';

export interface useProjectFormIF {
  submit: (params: undefined) => Promise<void>;
  isSubmitting: Ref<boolean>;
  form: ProjectForm | UpdateProjectForm | CloneProjectForm;
}

interface IProjectForm extends useProjectFormIF {
  isLoadingAreaOption: Ref<boolean>;
  areaOptions: Ref<AreaOptions>;
  area: Ref<AreaInfo | undefined>;
  isValidArea: Ref<boolean>;
  onChangeArea: () => void;
}

export enum EditType {
  create,
  edit,
  clone
}

export const useProjectForm = async (
  editType: EditType
): Promise<IProjectForm> => {
  let f: useProjectFormIF;
  const route = useRoute();

  if (editType === EditType.edit) {
    f = await useUpdateProjectForm();
  } else if (editType === EditType.clone) {
    f = await useCloneProjectForm();
  } else {
    f = useCreateProjectForm();
  }

  const { form, submit, isSubmitting } = f;

  const areaOptionStore = useAreaOptions();
  const {
    isLoading: isLoadingAreaOption,
    selectOptions: areaOptions
  } = storeToRefs(areaOptionStore);
  const area = computed(() => areaOptionStore.option(form.selectArea.value));
  const _area = await areaOptionStore.fetch(
    route,
    undefined,
    form.selectArea.value
  );
  const isValidArea = ref(
    areaOptionStore.option(form.selectArea.value) !== undefined ||
      editType === EditType.create
  );
  if (editType === EditType.create) {
    form.selectArea.value = _area;
  } else {
    form.selectArea.value = area.value?.id;
  }

  if (!isValidArea.value) {
    toast({
      title: 'エリアのオプション契約が変更されました',
      message: '使用可能なエリアからエリアを選択して設定を保存してください',
      variant: 'error'
    });
  }

  const onChangeArea = () => (isValidArea.value = true);

  return {
    form,
    submit,
    isSubmitting,
    isLoadingAreaOption,
    areaOptions,
    area,
    isValidArea,
    onChangeArea
  };
};
