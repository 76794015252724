import {
  CompanyApi,
  CustomTargetApi,
  ProjectApi,
  TvDataSearchApi
} from '@/api';
import {
  AreaInfoIdEnum,
  CustomTarget,
  PastComparison,
  ProductComparison,
  Products,
  ProjectBasicInfoCmBuyingKindEnum,
  ReachAnalysisProject,
  ReachAnalysisTargetSettingTargetTypeEnum,
  TvDataSearchCmCreative
} from '@/api/openapi';
import { TARGET_NAME } from '@/common/constant';
import { DATE_FORMAT } from '@/common/format';
import { resolveNumber, resolveUnit } from '@/common/formatter';
import { handleError } from '@/common/handleError';
import {
  VALIDATION_MESSAGE,
  validDuration as validateDuration
} from '@/common/validation';
import { DateRange } from '@/components/ui/DatePicker.vue';
import { toast } from '@/components/ui/Toast';
import { useProjectFormIF } from '@/composables/campaign/brand_lift/project';
import useLoading from '@/composables/loading';
import { CAMPAIGN_ROUTES } from '@/router';
import { differenceInDays, endOfMonth, format, startOfMonth } from 'date-fns';
import { Ref, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

export interface ProductSelectOption {
  label: string;
  value:
    | {
        id: number;
        isCmProduct: boolean;
      }
    | undefined;
}

export interface cmCreativeDataListType extends TvDataSearchCmCreative {
  select: number;
}

const NO_TARGET_COUNT_STRING = 'ー';
const TARTGET_COUNT_UNIT = '人';

export class ProjectForm {
  projectName: Ref<string>;
  duration: Ref<DateRange | undefined>;
  selectProduct: Ref<ProductSelectOption | undefined>;
  isLoadingFetchCmCreatives: Ref<boolean>;
  isCmCreativeOpen: Ref<boolean>;
  cmCreativeDataListItems: Ref<Array<cmCreativeDataListType>>;
  selectedCmCreativeIds: Ref<Array<number>>;
  selectedCmCreativeDataListItems: Ref<Array<cmCreativeDataListType>>;
  notIncludedListIds: Ref<Array<number>>;
  cmBuyingKind: Ref<ProjectBasicInfoCmBuyingKindEnum>;
  grp: Ref<string>;
  targetType: Ref<string>;
  targetName: Ref<ReachAnalysisTargetSettingTargetTypeEnum>;
  targets: Ref<Array<number>>;
  numOfEffectiveContacts: Ref<string>;
  selectArea: Ref<AreaInfoIdEnum | undefined>;
  cost: Ref<string>;
  targetEffectiveReachRate: Ref<string>;
  targetIsEnabled: Ref<boolean>;
  pastDurations: Ref<Array<DateRange | undefined>>;
  selectCompetitions: Ref<Array<ProductSelectOption | undefined>>;
  selectInHouses: Ref<Array<ProductSelectOption | undefined>>;

  productOption: Ref<Array<ProductSelectOption>>;
  competitiveProductOption: Ref<Array<ProductSelectOption>>;
  inHouseProductOption: Ref<Array<ProductSelectOption>>;

  products: Ref<Array<Products>>;
  competitiveProducts: Ref<Array<Products>>;

  targetCount: Ref<string>;
  targetCountUnit: Ref<string>;
  companyId: number;
  customTargetList: Ref<Array<CustomTarget> | undefined>;
  isLoading: Ref<boolean>;
  today = new Date();

  selectProductErrorMessage: Ref<string>;

  constructor(companyId: number) {
    this.projectName = ref('');
    this.duration = ref({
      start: startOfMonth(this.today),
      end: endOfMonth(this.today)
    });
    this.selectProduct = ref();
    this.isLoadingFetchCmCreatives = ref(false);
    this.isCmCreativeOpen = ref(false);
    this.cmCreativeDataListItems = ref([]);
    this.selectedCmCreativeIds = ref<number[]>([]);
    this.selectedCmCreativeDataListItems = ref([]);
    this.notIncludedListIds = ref<number[]>([]);
    this.cmBuyingKind = ref(ProjectBasicInfoCmBuyingKindEnum.All);
    this.grp = ref('');
    this.targetType = ref(TARGET_NAME.individual);
    this.targetName = ref(ReachAnalysisTargetSettingTargetTypeEnum.Individual);
    this.targets = ref<Array<number>>([]);
    this.numOfEffectiveContacts = ref('1');
    this.selectArea = ref<AreaInfoIdEnum>();
    this.cost = ref('');
    this.targetEffectiveReachRate = ref('');
    this.targetIsEnabled = ref(true);
    this.pastDurations = ref([undefined]);
    this.selectCompetitions = ref([undefined]);
    this.selectInHouses = ref([undefined]);

    this.productOption = ref([]);
    this.competitiveProductOption = ref([]);
    this.inHouseProductOption = ref([]);

    this.products = ref([]);
    this.competitiveProducts = ref([]);

    this.targetCount = ref(NO_TARGET_COUNT_STRING);
    this.targetCountUnit = ref(TARTGET_COUNT_UNIT);
    this.companyId = companyId;
    this.customTargetList = ref(undefined);
    this.isLoading = ref(false);
    this.selectProductErrorMessage = ref('');
    this.fetchCustomTarget().then();
  }

  fetchProducts = async (): Promise<void> => {
    if (this.duration.value?.start && this.duration.value?.end) {
      try {
        const result = await CompanyApi.getCompaniesCompanyIdProductsSearch(
          this.companyId,
          format(this.duration.value.start, DATE_FORMAT),
          format(this.duration.value.end, DATE_FORMAT),
          'CM_CREATIVE_DATE'
        );
        if (200 <= result.status && result.status < 300) {
          this.products.value = result.data.list ?? [];
          this.productOption.value = this.products.value.map(product => {
            return {
              label: product.name,
              value: {
                id: product.id,
                isCmProduct: product.isCmProduct
              }
            };
          });
        }
      } catch (e) {
        handleError(e, 'プロダクト一覧を取得できませんでした', '');
      }
    }
  };

  fetchCmCreatives = async (): Promise<void> => {
    try {
      this.isLoadingFetchCmCreatives.value = true;
      if (!this.isCmCreativeOpen.value) {
        this.cmCreativeDataListItems.value = [];
        this.selectedCmCreativeIds.value = [];
        this.selectedCmCreativeDataListItems.value = [];
        return;
      }
      const selectedProduct = this.products.value.find(
        value => value.id === this.selectProduct.value?.value?.id
      );
      if (!selectedProduct) return;
      const cmProductIds: number[] = [];
      cmProductIds.push(selectedProduct.mainCmProduct.id);
      selectedProduct.relatedCmProducts.map(v => {
        cmProductIds.push(v.id);
      });
      if (
        this.selectedCmCreativeIds.value.length > 0 &&
        this.cmCreativeDataListItems.value.length > 0
      ) {
        // 選択状態のリスト
        this.selectedCmCreativeDataListItems.value = this.cmCreativeDataListItems.value.filter(
          v => this.selectedCmCreativeIds.value.includes(v.id)
        );
      }
      if (this.duration.value?.start && this.duration.value?.end) {
        const result = await TvDataSearchApi.getTvdataSearchCmCreativesSearch(
          format(this.duration.value.start, DATE_FORMAT),
          format(this.duration.value.end, DATE_FORMAT),
          cmProductIds,
          this.companyId
        );
        if (200 <= result.status && result.status < 300) {
          this.cmCreativeDataListItems.value = result.data.map(v => {
            return {
              ...v,
              select: v.id
            };
          });
          // 前のCM素材取得処理後に選択状態であったが最新のCM素材取得処理レスポンスの一覧には含まれてないリスト
          // (画面で赤字表示するリスト)
          const notIncludedList = this.selectedCmCreativeDataListItems.value.filter(
            item => !result.data.map(data => data.id).includes(item.id)
          );
          this.notIncludedListIds.value = notIncludedList.map(v => v.id);
          this.cmCreativeDataListItems.value = this.cmCreativeDataListItems.value
            .concat(notIncludedList)
            .sort((a, b) => a.id - b.id);
        }
      }
    } catch (e) {
      handleError(e, 'CM素材を取得できませんでした', '');
    } finally {
      this.isLoadingFetchCmCreatives.value = false;
    }
  };

  checkCmCreatives = (): boolean => {
    let erroMsg = '';
    if (!this.isCmCreativeOpen.value) return true;
    if (this.selectedCmCreativeIds.value.length === 0) {
      erroMsg =
        'CM素材が指定されていません。全ての素材を対象とする場合は、「CM素材を指定する」をOFFにしてください。';
    } else {
      const check = this.selectedCmCreativeIds.value.some(
        id => !this.notIncludedListIds.value.map(nId => nId).includes(id)
      );
      if (!check) {
        erroMsg = '有効なCM素材を選択してください。';
      }
    }
    if (erroMsg) {
      toast({ title: '失敗', message: erroMsg, variant: 'error' });
      return false;
    } else {
      return true;
    }
  };

  onClickFetchTargetCount = async (): Promise<void> => {
    if (
      this.duration.value?.start &&
      this.duration.value?.end &&
      this.selectArea.value
    ) {
      try {
        const result = await ProjectApi.getTargetReachNumber(
          parseInt(this.targetEffectiveReachRate.value),
          format(this.duration.value.start, DATE_FORMAT),
          format(this.duration.value.end, DATE_FORMAT),
          this.selectArea.value,
          this.targetType.value === TARGET_NAME.customTarget,
          this.companyId,
          this.targets.value.filter(value => value !== undefined)
        );
        if (200 <= result.status && result.status < 300) {
          const { reachNumber } = result.data;
          if (reachNumber !== undefined) {
            this.targetCount.value = resolveNumber(reachNumber, 3);
            this.targetCountUnit.value = resolveUnit(
              reachNumber,
              TARTGET_COUNT_UNIT
            );
          }
        } else {
          this.targetCount.value = NO_TARGET_COUNT_STRING;
          this.targetCountUnit.value = TARTGET_COUNT_UNIT;
        }
      } catch (e) {
        handleError(e, 'リーチ人数取得に失敗しました', '');
      }
    }
  };

  onChangeTarget = (target: {
    name: string;
    value:
      | Array<number>
      | { label: string; id: number }
      | { label: string; id: number; isEnabled: boolean };
  }): void => {
    if (target.name === TARGET_NAME.individual.toString()) {
      this.targetName.value =
        ReachAnalysisTargetSettingTargetTypeEnum.Individual;
    } else if (target.name === TARGET_NAME.customTarget.toString()) {
      this.targetName.value = ReachAnalysisTargetSettingTargetTypeEnum.Custom;
    } else if (target.name === TARGET_NAME.genderAge12Type) {
      this.targetName.value =
        ReachAnalysisTargetSettingTargetTypeEnum.BasicGa12;
    } else {
      this.targetName.value =
        ReachAnalysisTargetSettingTargetTypeEnum.BasicGa10S;
    }
    this.targetType.value = target.name;
    if (target.value) {
      this.targets.value = Array.isArray(target.value)
        ? target.value
        : [target.value.id];
      // Todo: 構文修正予定
      const v = target.value as {
        label: string;
        id: number;
        isEnabled: boolean;
      };
      this.targetIsEnabled.value = v.isEnabled ?? true;
    }
  };

  onSelectDuration = async (): Promise<void> => {
    await this.fetchProducts();
    const _inProducts = this.products.value.find(
      v => v.id === this.selectProduct.value?.value?.id
    );
    if (_inProducts === undefined) {
      this.selectProduct.value = undefined;
      this.selectProductErrorMessage.value =
        '選択肢の中から再度商品を選択してください';
    }
    if (this.productOption.value.length === 0) {
      this.selectProductErrorMessage.value = '指定した期間に出稿がありません';
    }
    this.selectInHouses.value = [undefined];
    this.selectCompetitions.value = [undefined];
    this.targetCount.value = NO_TARGET_COUNT_STRING;
    this.targetCountUnit.value = TARTGET_COUNT_UNIT;
  };

  onSelectProduct(productId?: number): void {
    this.selectProductErrorMessage.value = '';
    const selectedProduct = this.products.value.find(
      value => value.id === productId
    );
    this.selectProduct.value = selectedProduct
      ? {
          label: selectedProduct.name,
          value: {
            id: selectedProduct.id,
            isCmProduct: selectedProduct.isCmProduct
          }
        }
      : undefined;
    this.competitiveProductOption.value =
      selectedProduct?.competitiveProducts?.map(product => {
        return {
          label: product.competitiveProductName,
          value: { id: product.id, isCmProduct: false }
        };
      }) ?? [];
    this.inHouseProductOption.value = this.products.value
      .map(product => {
        return {
          label: product.name,
          value: {
            id: product.id,
            isCmProduct: product.isCmProduct
          }
        };
      })
      .filter(
        value =>
          value.value?.id !== undefined &&
          value.value.id !== selectedProduct?.id
      );
    this.inHouseProductOption.value = [
      { label: '未選択', value: undefined },
      ...this.inHouseProductOption.value
    ];
    this.competitiveProductOption.value = [
      { label: '未選択', value: undefined },
      ...this.competitiveProductOption.value
    ];
  }

  filterProductOption = (): void => {
    const selectInHousesIds = this.selectInHouses?.value
      .filter(value => value?.value?.id !== undefined)
      .map(value => {
        return value?.value?.id as number;
      });

    this.productOption.value = this.products.value
      .map(product => {
        return {
          label: product.name,
          value: {
            id: product.id,
            isCmProduct: product.isCmProduct
          }
        };
      })
      .filter(
        value =>
          value.value?.id !== undefined &&
          !selectInHousesIds.includes(value?.value?.id)
      );
  };

  private async fetchCustomTarget() {
    if (!this.isLoading.value) {
      this.isLoading.value = true;
    }
    try {
      const result = await CustomTargetApi.getCompaniesCompanyIdCustomTargets(
        this.companyId
      );
      this.customTargetList.value = result.data.list;
    } catch (e) {
      handleError(e, 'カスタムターゲット一覧取得に失敗しました', '');
    }
  }

  toPayload(): ReachAnalysisProject | undefined {
    if (
      this.duration.value?.start &&
      this.duration.value?.end &&
      this.selectProduct.value &&
      this.selectArea.value
    ) {
      const selectInHouses = this.selectInHouses.value;
      // const selectCompetitions = this.selectCompetitions.value;
      return {
        basicInfo: {
          projectName: this.projectName.value,
          campaignStartDate: format(this.duration.value.start, DATE_FORMAT),
          campaignEndDate: format(this.duration.value.end, DATE_FORMAT),
          isCmProduct: this.selectProduct.value?.value?.isCmProduct,
          productId: this.selectProduct.value?.value?.id ?? -1,
          targetGrp: parseInt(this.grp.value.replaceAll(',', '')),
          cmBuyingKind: this.cmBuyingKind.value,
          cmCreativeIds: this.selectedCmCreativeIds.value
        },
        targetSettings: {
          targetType: this.targetName.value,
          targets: this.targets.value
            .filter(target => target !== undefined)
            .map(target => {
              return { targetId: target };
            }),
          area: this.selectArea.value,
          numOfEffectiveContacts: parseInt(this.numOfEffectiveContacts.value),
          totalPlacementCost: parseInt(this.cost.value.replaceAll(',', '')),
          targetEffectiveReachRate: parseInt(
            this.targetEffectiveReachRate.value === ''
              ? '0'
              : this.targetEffectiveReachRate.value
          )
        },
        comparisonSettings: {
          productComparisonProducts: this.selectCompetitions.value
            .map(competitive => {
              if (
                competitive !== undefined &&
                competitive.value !== undefined
              ) {
                return {
                  productId: competitive.value.id,
                  isCmProduct: competitive.value.isCmProduct
                } as ProductComparison;
              }
            })
            .filter(
              competitive => competitive !== undefined
            ) as ProductComparison[],
          pastComparisons: this.pastDurations.value
            .map(past => {
              if (past?.start && past?.end)
                return {
                  startDate: format(past.start, DATE_FORMAT),
                  endDate: format(past.end, DATE_FORMAT)
                } as PastComparison;
            })
            .filter(past => past !== undefined) as PastComparison[],
          inhouseComparisonProducts: this.products.value
            .filter(product =>
              selectInHouses.find(select => select?.value?.id === product.id)
            )
            .map(product => {
              return {
                productId: product.id,
                isCmProduct: product.isCmProduct
              };
            })
        }
      };
    }
  }

  async createProject(): Promise<void> {
    await CompanyApi.postCompaniesCompanyIdReachAnalysisProjects(
      this.companyId,
      this.toPayload()
    );
  }
}

export const useCreateProjectForm = (): useProjectFormIF => {
  const router = useRouter();
  const route = useRoute();
  const companyId = route.params.companyId?.toString();

  if (!companyId) {
    toast({ title: 'カンパニーが選択されていません', variant: 'error' });
  }

  const form = new ProjectForm(parseInt(companyId ?? '0'));

  const _createProject = async () => {
    if (!form.checkCmCreatives()) return;
    try {
      await form.createProject();
      toast({
        title: '成功',
        message: '作成に成功しました',
        variant: 'success'
      });
      await router.push({
        name: CAMPAIGN_ROUTES.index,
        params: route.params
      });
    } catch (e) {
      handleError(e);
    }
  };

  const [isCreatingProject, createProject] = useLoading(_createProject);

  return {
    form,
    submit: createProject,
    isSubmitting: isCreatingProject
  };
};

interface UseValidatorReturnType {
  validReachRate: (value: string) => string;
  validDigit: (value: string) => string;
  validDuration: (value: DateRange) => string;
  validProjectName: (value: string) => string;
}

export const useValidator = (): UseValidatorReturnType => {
  const validReachRate = (value: string) => {
    if (value === '') return '';
    const reach = Number(value);
    if (Number.isNaN(reach) || 1 > reach || reach > 100) {
      return VALIDATION_MESSAGE.invalidNumberWidth(1, 100);
    }
    return '';
  };
  const validDigit = (value: string) => {
    const num = Number(value.replaceAll(',', ''));
    if (value != '' && (Number.isNaN(num) || num >= 1000000)) {
      return VALIDATION_MESSAGE.invalidNumberWidth(0, 999999);
    }
    return '';
  };
  const validDuration = (value: DateRange) => {
    if (differenceInDays(value?.end as Date, value?.start as Date) > 365) {
      return VALIDATION_MESSAGE.duration('1年以内');
    } else if (!validateDuration(value)) {
      return VALIDATION_MESSAGE.duration('7日間以上');
    }
    return '';
  };
  const validProjectName = (value: string) => {
    if (value.length > 64) {
      return VALIDATION_MESSAGE.max64;
    }
    if (value.length && !value.match(/\S/g)) {
      return VALIDATION_MESSAGE.emptyFormat;
    }
    return '';
  };
  return {
    validReachRate,
    validDigit,
    validDuration,
    validProjectName
  };
};
