import { ProjectApi } from '@/api';
import {
  AreaInfoIdEnum,
  ProjectBasicInfoCmBuyingKindEnum,
  ReachAnalysisProject,
  ReachAnalysisTargetSettingTargetTypeEnum
} from '@/api/openapi';
import { toast } from '@/components/ui/Toast';
import {
  cmCreativeDataListType,
  ProductSelectOption,
  ProjectForm
} from '@/composables/campaign/brand_lift/create';
import useLoading from '@/composables/loading';
import { CAMPAIGN_ROUTES } from '@/router';
import { useRoute, useRouter } from 'vue-router';

import { DATE_FORMAT } from '@/common/format';
import { handleError } from '@/common/handleError';
import { DateRange } from '@/components/ui/DatePicker.vue';
import { convertToTarget } from '@/components/ui/SelectTarget.vue';
import { useProjectFormIF } from '@/composables/campaign/brand_lift/project';
import { parse } from 'date-fns';

const toDuration = (start: string, end: string): DateRange => {
  return {
    start: parse(start, DATE_FORMAT, new Date()),
    end: parse(end, DATE_FORMAT, new Date())
  };
};

export class UpdateProjectForm extends ProjectForm {
  async fromReachAnalysisProject(projectId?: number): Promise<void> {
    if (!this.isLoading.value) {
      this.isLoading.value = true;
    }
    const value = await this.fetchProject(projectId);
    if (value) {
      this.projectName.value = value.basicInfo?.projectName ?? '';
      const start = value.basicInfo?.campaignStartDate ?? undefined;
      const end = value.basicInfo?.campaignEndDate ?? undefined;

      this.duration.value = start && end ? toDuration(start, end) : undefined;
      await this.fetchProducts();
      const productId = value.basicInfo?.productId;
      this.onSelectProduct(productId);
      if (
        value.basicInfo.cmCreatives &&
        value.basicInfo.cmCreatives.length > 0
      ) {
        this.selectedCmCreativeIds.value = value.basicInfo.cmCreatives.map(
          v => v.id
        );
        this.selectedCmCreativeDataListItems.value = value.basicInfo.cmCreatives.map(
          v => ({
            ...v,
            select: v.id
          })
        ) as cmCreativeDataListType[];
        this.isCmCreativeOpen.value = true;
        await this.fetchCmCreatives();
      }
      this.cmBuyingKind.value =
        value.basicInfo?.cmBuyingKind ?? ProjectBasicInfoCmBuyingKindEnum.All;

      this.grp.value = value.basicInfo?.targetGrp.toString() ?? '';
      this.targetName.value =
        value.targetSettings?.targetType ??
        ReachAnalysisTargetSettingTargetTypeEnum.Individual;
      this.targetType.value = convertToTarget(value.targetSettings?.targetType);
      this.targets.value = value.targetSettings?.targets
        .map(value => value.targetId)
        .filter(value => value) as number[];

      this.numOfEffectiveContacts.value =
        value.targetSettings?.numOfEffectiveContacts.toString() ?? '1';
      // 無理やりareaに値を入れているが、値が存在しないなんてことはないからこのopはされない想定
      this.selectArea.value = value.targetSettings.area as AreaInfoIdEnum;
      this.cost.value =
        value.targetSettings?.totalPlacementCost.toString() ?? '';
      const reachRate = value.targetSettings?.targetEffectiveReachRate;
      this.targetEffectiveReachRate.value =
        reachRate === undefined || reachRate === 0 ? '' : reachRate.toString();
      this.targetIsEnabled.value =
        value.targetSettings?.targetIsEnabled ?? true;
      this.pastDurations.value =
        value.comparisonSettings?.pastComparisons
          ?.map(comparisons => {
            return comparisons.startDate && comparisons.endDate
              ? {
                  start: parse(comparisons.startDate, DATE_FORMAT, new Date()),
                  end: parse(comparisons.endDate, DATE_FORMAT, new Date())
                }
              : undefined;
          })
          .filter(comparisons => comparisons !== undefined) ?? [];
      if (this.pastDurations.value.length === 0)
        this.pastDurations.value = [undefined];
      this.selectCompetitions.value =
        value.comparisonSettings?.productComparisonProducts
          ?.map(comparisons => {
            return this.competitiveProductOption.value.find(
              cp => cp.value?.id === comparisons.productId
            );
          })
          .filter(comparisons => comparisons !== undefined) ?? [];
      if (this.selectCompetitions.value.length === 0) {
        this.selectCompetitions.value = [undefined];
      }
      this.selectInHouses.value =
        value.comparisonSettings?.inhouseComparisonProducts
          ?.map(comparisons => {
            const selectInHouse = this.products.value.find(
              product => product.id === comparisons.productId
            );
            if (selectInHouse)
              return {
                label: selectInHouse.name,
                value: {
                  id: selectInHouse.id,
                  isCmProduct: selectInHouse.isCmProduct
                }
              } as ProductSelectOption;
          })
          .filter(comparisons => comparisons !== undefined) ?? [];
      if (this.selectInHouses.value.length === 0) {
        this.selectInHouses.value = [undefined];
      }
    }
    this.isLoading.value = false;
  }
  private async fetchProject(
    projectId?: number
  ): Promise<ReachAnalysisProject | undefined> {
    try {
      if (!projectId) {
        return;
      }
      const result = await ProjectApi.getReachAnalysisProject(projectId);
      return result.data;
    } catch (e) {
      handleError(e, 'プロジェクト情報を取得できませんでした');
    }
  }
  async updateProject(projectId: number): Promise<ReachAnalysisProject> {
    const response = await ProjectApi.putReachAnalysisProjectsReachAnalysisProjectId(
      projectId,
      this.toPayload()
    );
    return response.data;
  }
}

export const useUpdateProjectForm = async (): Promise<useProjectFormIF> => {
  const route = useRoute();
  const router = useRouter();
  const projectId = Number(route.params.projectId);

  const companyId = Number(route.params.companyId);
  if (!companyId) {
    toast({ title: 'カンパニーが選択されていません', variant: 'error' });
  }
  const form = new UpdateProjectForm(companyId);
  await form.fromReachAnalysisProject(projectId);
  const _updateProject = async () => {
    if (!form.checkCmCreatives()) return;
    try {
      await form.updateProject(projectId);
      await router.push({
        name: CAMPAIGN_ROUTES.index,
        params: route.params
      });
    } catch (e) {
      handleError(e);
    }
  };

  const [isUpdatingProject, updateProject] = useLoading(_updateProject);

  return {
    form,
    submit: updateProject,
    isSubmitting: isUpdatingProject
  };
};
